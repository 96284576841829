/* -----------Components------------ */
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsSection from './section-components/property_details/property-details';
import RecomandProperties from './section-components/recomand-properties';
import Footer from './global-components/footer';
/* -----------Assets------------ */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
/* -----------Selectors------------ */
import { PropDetailSelector } from '../redux/properties/index.selector';
/* -----------Styles------------ */

const PropertyDetails = () => {
    /* -----------Selectors------------ */
    const propDetail = useSelector(PropDetailSelector);
    const { img } = propDetail;
    /* -----------Effect Hooks------------ */
    useEffect(() => {
        const rootElement = document.documentElement;
        rootElement.scrollTop = 0;
    }, [])

    return (
        <div>
            <Navbar />
            <PageHeader headertitle={propDetail?.Denomination} subheader="Property details" />
            <PropertyDetailsSection />
            <Footer />
        </div>
    )
}

export default PropertyDetails

