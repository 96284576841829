/* -----------Assets------------ */
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import SearchList from './section-components/search_list/search-list';
import Footer from './global-components/footer';
import { loadPropsAPI, loadPropsAreaAPI, loadPropsCategAPI } from '../redux/properties/index.api';
import { initFilter } from '../redux/properties/index.slice';
/* -----------Style Sheet------------ */

const SearchListSection = () => {

    /* -----------Vars------------ */
    const dispatch = useDispatch();
    /* -----------Effect Hook------------ */
    useEffect(() => {
        dispatch(loadPropsAPI({ section: 'search' }));
        dispatch(loadPropsAreaAPI({}));
        dispatch(loadPropsCategAPI({}))
        dispatch(initFilter())
    }, []);
    return (
        <div>
            <Navbar />
            <PageHeader
                headertitle="Nos biens à la vente"
                subheadertitle="Valucian votre partenaire en cession de fonds de commerces"
                subheader="Biens à vendre" />
            <SearchList />
            <Footer />
        </div>
    )
};

export default SearchListSection

