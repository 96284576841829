/* -----------Assets------------ */
import React from 'react';
import Empty from 'antd/lib/empty';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import placeholder_prop from "../../../assets/images/placeholder_prop.jpeg";
/* -----------Selectors------------ */
import { PropListSelector } from '../../../redux/properties/index.selector';
import { referentielSelector } from '../../../redux/referentiel/index.selector';
/* -----------Style Sheet------------ */
import "./index.css";
import { loadPropDetailsAPI } from '../../../redux/properties/index.api';



const Featured = (props) => {
  /* -----------Selector------------ */
  const propsList = useSelector(PropListSelector);
  const appVersion = useSelector(referentielSelector);
  /* -----------Vars------------ */
  const dispatch = useDispatch();
  let publicUrl = process.env.PUBLIC_URL
  let noData = propsList.length < 0;
  let Customclass = props.Customclass ? props.Customclass : 'pd-top-60'
  /* -----------FUNCTIONS------------ */
  const toDetails_handler = (id) => {
    let payload = {
      section: "detail",
      property: id
    }
    dispatch(loadPropDetailsAPI(payload))
  }

  return (
    <div className={"featured-area  " + Customclass}>
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title">Featured Properties</h2>
        </div>
        <div className="row justify-content-center"
          style={{ boxShadow: noData ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : 'none' }}>
          {noData ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            :
            propsList.map((item, i) => {
              let { IDProperty, areaName, imgSrc, imgAlt, Denomination, PrixEuros, nbBedroom, nbBathroom, surfaceBien, EBE, CAHT, effectifs } = item;
              return (
                <div key={i} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="single-feature">
                    <div className="thumb">
                      <img src={imgSrc ? imgSrc : placeholder_prop} alt={imgAlt} />
                    </div>
                    <div className="details">
                      <p className="author"><i className="fa fa-map-marker" /> {areaName}</p>
                      <h6 className="title readeal-top" title={Denomination}><a>{Denomination}</a></h6>
                      <h6 className="price">
                        {PrixEuros.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                      </h6>
                      <ul className="info-list">
                        {appVersion.version === "cfc" ?
                          <>
                            <li key={i} ><span>CA:</span>{CAHT ? CAHT : "--"} €</li>
                            <li key={i} ><span>Effectifs:</span> {effectifs ? effectifs : "--"}</li>
                            <li key={i} ><span>EBE:</span> {EBE ? EBE : "--"} €</li>
                          </>
                          :
                          <>
                            <li key={i} ><i className="fa fa-bed" /> {nbBedroom ? nbBedroom : "--"} Bed</li>
                            <li key={i} ><i className="fa fa-bath" /> {nbBathroom ? nbBathroom : "--"} Bath</li>
                            <li>
                              <img src={publicUrl + "/assets/img/icons/7.png"} alt="img" />
                              {surfaceBien ? surfaceBien : "--"} sq
                            </li>
                          </>
                        }
                      </ul>
                      <ul className="contact-list">
                        <li><Link className="phone" to="/contact"><i className="fa fa-phone" /></Link></li>
                        <li className="readeal-top" onClick={() => { toDetails_handler(IDProperty) }}><Link className="btn btn-yellow" to="/property-details">View Details</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div >
  );
}

export default Featured;