const API_ENDPOINT = {
  /*
   ** GLOBAL **
   */
  SEND_MAIL: "/sendmail",
  LOAD_VERSION: "api-neelaps.config",
  /*
   ** PROPS **
   */
  LOAD_PROPS: "/web/properties",
  LOAD_PROPS_AREA: "/properties-area",
  LOAD_PROPS_CATEG: "/properties-categories",
};

export default API_ENDPOINT;
