import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./index.css";

const Page_header = ({ headertitle, subheadertitle, subheader }) => {


  let publicUrl = process.env.PUBLIC_URL
  const inlineStyle = {
    backgroundImage: 'url(' + publicUrl + '/assets/img/bg/4.png)'
  }

  return (
    <div className="breadcrumb-area jarallax" style={inlineStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner">
              <h1 className="page-title">{headertitle}</h1>
              <h4>{subheadertitle}</h4>
              <ul className="page-list">
                <li><Link to="/">Home</Link></li>
                <li>{subheader}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page_header;