/* -----------Components------------ */

/* -----------Assets------------ */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Empty from 'antd/lib/empty';
import Input from "antd/lib/input";
import Carousel from 'antd/lib/carousel';
import Select from "antd/lib/select";
import _ from "lodash";
import { ObjetToArr } from "../../../assets/functions";
/* -----------Selectors------------ */
import { PropDetailSelector } from "../../../redux/properties/index.selector";
/* -----------Styles------------ */
import "./index.css"
import { setContactForm } from "../../../redux/contact/index.slice";
import { contactFormSelector } from "../../../redux/contact/index.selector";
import { sendMailContactAPI } from "../../../redux/contact/index.api";
import NotificationComponent from "../../alert-component/component";



const PropertyDetails = () => {

  /* -----------Selectors------------ */
  const propDetail = useSelector(PropDetailSelector);
  const contactForm = useSelector(contactFormSelector)
  /* -----------VARS------------ */
  const { img, C_nbBedroom, C_nbBathroom, C_surfaceBien, C_nbParking, propertyPointsForts, propertyEquipments, propertyServices, statut, familyName, categories, CodeInterne, areaName, PrixEuros, Denomination, paysName } = propDetail;
  let publicUrl = process.env.PUBLIC_URL
  const { TextArea } = Input;
  const dispatch = useDispatch();
  let imagealt = 'image';
  const synthese = {
    statut: statut ? statut : "--",
    Famille: familyName ? familyName : "--",
    Catégories: categories ? categories : "--",
    "Code interne": CodeInterne ? CodeInterne : "--",
    "Secteur géographique": areaName ? areaName : "--",
    Pays: paysName ? paysName : "--"
  }
  const jourContact = [
    { value: 'Lundi', label: 'Lundi' },
    { value: 'Mardi', label: 'Mardi' },
    { value: 'Mercredi', label: 'Mercredi' },
    { value: 'Jeudi', label: 'Jeudi' },
    { value: 'Vendredi', label: 'Vendredi' },
    { value: 'Peu Importe', label: 'Peu Importe' },
    { value: 'Ne me rappelez pas...', label: 'Ne me rappelez pas...' },
  ]
  const heureContact = [
    { value: 'Entre 9H et 12H', label: 'Entre 9H et 12H' },
    { value: 'Entre 12H et 14H', label: 'Entre 12H et 14H' },
    { value: 'Entre 14H et 17H', label: 'Entre 14H et 17H' },
    { value: 'Après 17H', label: 'Après 17H' },
  ]
  /* -----------FUNCTIONS------------ */
  const onFormChange_handler = (name, e) => {
    let form = _.cloneDeep(contactForm);
    if (["contactName", "contactMail", "contactPhone", "contactMessage"].includes(name)) {
      let { target: { value } } = e;
      dispatch(setContactForm({ ...form, [name]: value }))
    } else {
      dispatch(setContactForm({ ...form, [name]: e }))
    }
  }
  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }
  const sendMessage_handler = (e) => {
    e.preventDefault();
    if (contactForm.contactName && contactForm.contactMail && ValidateEmail(contactForm.contactMail)) {
      dispatch(sendMailContactAPI(contactForm))

    } else {
      NotificationComponent(
        true,
        "error",
        "Echec de l'envoi du formulaire",
        contactForm.contactName, contactForm.contactMail, ValidateEmail(contactForm.contactMail))
    }
  }

  return (
    <div className="property-details-area">
      <div className="bg-gray pd-top-100 pd-bottom-90">
        <div className="container">
          <div className="row ">
            <div className="col-xl-9 col-lg-8">
              <div className="property-details-slider">
                <div className="item">
                  <div className="thumb">
                    <Carousel autoplay>
                      {(img ? img : []).map(item => <img src={item?.imgSrc} alt={item?.imgAlt} />)}
                    </Carousel>
                  </div>
                </div>
              </div>
              <div className="property-details-slider-info">
                <h3><span>{parseFloat(PrixEuros).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}</span> {Denomination}</h3>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <div className="contact">
                  <h6>Contact Us</h6>
                  <div className="rld-single-input">
                    <input type="text" placeholder="Nom et prénom" onChange={(e) => { onFormChange_handler("contactName", e) }} />
                  </div>
                  <div className="rld-single-input">
                    <input type="text" placeholder="Adresse mail" onChange={(e) => { onFormChange_handler("contactMail", e) }} />
                  </div>
                  <div className="rld-single-input">
                    <Select
                      placeholder="Je souhaite être appelé le..."
                      bordered={false}
                      onChange={(e) => { onFormChange_handler("contactJour", e) }}
                      options={jourContact}
                      allowClear={true}
                    />
                  </div>
                  <div className="rld-single-input">
                    <Select
                      placeholder="Je préfère être appelé..."
                      bordered={false}
                      onChange={(e) => { onFormChange_handler("contactHoraire", e) }}
                      options={heureContact}
                      allowClear={true}
                    />
                  </div>
                  <div className="rld-single-input">
                    <input type="text" placeholder="Téléphone" onChange={(e) => { onFormChange_handler("contactPhone", e) }} />
                  </div>
                  <div className="rld-single-input">
                    <Select
                      placeholder="Je suis..."
                      bordered={false}
                      allowClear={true}
                      onChange={(e) => { onFormChange_handler("contactSubject", e) }}
                      options={[
                        { value: 'Je suis vendeur', label: 'Je suis vendeur' },
                        { value: 'Je suis acheteur', label: 'Je suis acheteur' },
                        { value: 'Autre', label: 'Autre' },
                      ]}
                    />
                  </div>
                  <div className="rld-single-input">
                    <TextArea rows={4} bordered={false} placeholder="Message" onChange={(e) => { onFormChange_handler("contactMessage", e) }} />
                  </div>
                  <a className="btn btn-yellow" href="#" onClick={sendMessage_handler}>Send Messages</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pd-top-90">
          <div className="col-lg-9">
            <div className="property-info mb-5">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Bedrooms</h5>
                    <p><i className="fa fa-bed" />{C_nbBedroom ? C_nbBedroom : "--"}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Bathrooms</h5>
                    <p><i className="fa fa-bath" />{C_nbBathroom ? C_nbBathroom : "--"}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Area</h5>
                    <p><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} />{C_surfaceBien ? C_surfaceBien : "--"} sq. ft.</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Parking</h5>
                    <p><i className="fa fa-car" />{C_nbParking ? C_nbParking : "--"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Valucian, votre agence spécilisée en cession de fonds de commerce, vous propose:</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In bibendum elit magna, ut placerat nunc tempus vel. Donec vitae dictum ligula. Phasellus congue maximus eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Suspendisse potenti. Suspendisse sollicitudin posuere nunc et vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas aliquam vitae quam at sodales. </p>
            </div>
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Informations</h4>
              <div className="row">
                {!propertyPointsForts && !propertyServices && !propertyEquipments ?
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  :
                  <>
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        {ObjetToArr(propertyPointsForts).map(item =>
                          <li><i className="fa fa-check" /> {item.strengthsName}</li>
                        )}
                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        {ObjetToArr(propertyServices).map(item =>
                          <li><i className="fa fa-check" /> {item.servicesName}</li>
                        )}
                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        {ObjetToArr(propertyEquipments).map(item =>
                          <li><i className="fa fa-check" /> {item.equipementName}</li>
                        )}
                      </ul>
                    </div></>
                }

              </div>
            </div>
            <div className="property-news-single-card border-bottom-yellow pb-3" style={{ borderBottom: "none", marginBottom: "0px" }}>
              <h4>Synthèse</h4>
              <div className="row">
                {
                  Object.keys(synthese).map(item => {
                    return (
                      <div className="col-md-3 col-sm-6">
                        <div className="single-floor-list media">
                          <div className="media-left">
                            <i className="fa fa-hashtag" aria-hidden="true" />
                          </div>
                          <div className="media-body">
                            <h6>{item}</h6>
                            <p>{synthese[item]}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PropertyDetails