/* -----------Components------------ */
import Home from './components/home-v1';
import HomeV2 from './components/home-v2';
import HomeV3 from './components/home-v3';
import HomeV4 from './components/home-v4';
import Property from './components/property';
import AvilableProperty from './components/availavbe-property';
import PropertiesByCity from './components/properties-by-city';
import RecentProperties from './components/recent-properties';
import PropertyDetails from './components/property-details';
import About from './components/about';
import Advisor from './components/advisor';
import Pricing from './components/pricing';
import UserList from './components/user-list';
import Registraion from './components/registration';
import Error from './components/error';
import Faq from './components/faq';
import News from './components/news';
import NewsDetails from './components/news-details';
import Contact from './components/contact';
import SearchMap from './components/search-map';
import SearchGrid from './components/search-grid';
import AddNew from './components/add-property';
import SearchListSection from './components/search-list';
/* -----------Assets------------ */
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Routes, Route } from 'react-router-dom';
import { loadVersionAPI } from './redux/referentiel/index.api';
/* -----------Style Sheet------------ */



const App = () => {
    /* -----------Vars------------ */
    const dispatch = useDispatch();
    /* -----------Effect Hook------------ */
    useEffect(() => {
        dispatch(loadVersionAPI({ get: 'neelaps-version' }));
    }, []);

    return (
        <Routes basename="/">
            <Route path="/" element={<Home />} />
            <Route path="/home-v2" element={<HomeV2 />} />
            <Route path="/home-v3" element={<HomeV3 />} />
            <Route path="/home-v4" element={<HomeV4 />} />
            <Route path="/property" element={<Property />} />
            <Route path="/availavbe-property" element={<AvilableProperty />} />
            <Route path="/properties-by-city" element={<PropertiesByCity />} />
            <Route path="/recent-properties" element={<RecentProperties />} />
            <Route path="/property-details" element={<PropertyDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/advisor" element={<Advisor />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/registration" element={<Registraion />} />
            <Route path="/error" element={<Error />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/news" element={<News />} />
            <Route path="/news-details" element={<NewsDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/search-map" element={<SearchMap />} />
            <Route path="/search-grid" element={<SearchGrid />} />
            <Route path="/search-list" element={<SearchListSection />} />
            <Route path="/add-property" element={<AddNew />} />
        </Routes>

    )

}

export default App;
