/* -----------Components------------ */
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Service from './section-components/service';
import Explore from './section-components/explore';
import FeaturedProperties from './section-components/featured_prop/featured-properties';
import Ads from './section-components/ads';
import PropertiesByCities from './section-components/properties-by-cities';
import RecentProperties from './section-components/recent-properties';
import FeaturedPorject from './section-components/featured-project';
import WhyChooseUs from './section-components/why-choose-us';
import OurPartner from './section-components/our-partner';
import Footer from './global-components/footer';
/* -----------Assets------------ */
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { loadPropsAPI } from '../redux/properties/index.api';
/* -----------Style------------ */



const Home_V1 = () => {
    /* -----------VARS------------ */
    const dispatch = useDispatch();
    /* -----------Effect Hook------------ */
    useEffect(() => {
        dispatch(loadPropsAPI({ section: 'homepage' }))
    }, []);
    /* -----------Assets------------ */
    return (
        <div>
            <Navbar />
            <Banner />
            <Service />
            <Explore />
            <FeaturedProperties />
            <Ads />
            <PropertiesByCities />
            <RecentProperties />
            <FeaturedPorject />
            <WhyChooseUs />
            <OurPartner />
            <Footer />
        </div>
    );
}

export default Home_V1

