import { combineReducers } from "redux";
/* -----------Reducers------------ */
import contactSlice from "./contact/index.slice";
import propertieSlice from "./properties/index.slice";
import referentielSlice from "./referentiel/index.slice";

/* -----------COMBINE------------ */
const rootReducer = combineReducers({
  contact: contactSlice,
  properties: propertieSlice,
  referentiel: referentielSlice
});

export default rootReducer;
