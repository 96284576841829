/* -----------Components---------------- */
import NotificationComponent from './alert-component/component';
import Footer from './global-components/footer';
import FaqSection from './section-components/faq';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
/* -----------Assets---------------- */
import _ from "lodash";
import React, { useState } from 'react';
import { env } from '../environnement';
import { useDispatch, useSelector } from "react-redux";
import ReCaptchaV2 from 'react-google-recaptcha';
import { sendMailContactAPI } from '../redux/contact/index.api';
import { setContactForm } from '../redux/contact/index.slice';
/* -----------Selectors------------ */
import { contactFormSelector } from '../redux/contact/index.selector';
/* -----------Style Sheet------------ */


const Contact = () => {
	/* -----------Selectors------------ */
	const form = useSelector(contactFormSelector);
	/* -----------State Hook------------ */
	/* -----------Vars------------ */
	const dispatch = useDispatch();
	const recaptchaRef = React.createRef();

	/* -----------Functions------------ */
	const onFormChange_handler = (name, e) => {
		let payload = _.cloneDeep(form);
		if (name === "token") {
			if (e) {
				dispatch(setContactForm({
					...payload,
					[name]: e
				}))
			} else {
				delete payload[name]
				dispatch(setContactForm({ ...payload }))
			}
		} else {
			let { target: { value } } = e;
			if (value) {
				dispatch(setContactForm({
					...payload,
					[name]: value
				}))
			} else {
				delete payload[name]
				dispatch(setContactForm({ ...payload }))
			}

		}
	}
	const ValidateEmail = (mail) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true
		}
		return false
	}
	const handle_submit = (e) => {
		e.preventDefault();
		if (form.contactName && form.contactMail && ValidateEmail(form.contactMail) && form.token) {
			let payload = _.cloneDeep(form);
			delete payload.token;
			dispatch(sendMailContactAPI(payload))

		} else {
			NotificationComponent(
				true,
				"error",
				"Echec de l'envoi du formulaire",
				form.contactName, form.contactMail, ValidateEmail(form.contactMail), form.token,
			)
		}
	}
	const onOk_handler = () => {
		window.alert("haa")
	}
	/* -----------render------------ */
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Contact" />
			<div className="contact-area pd-top-100 pd-bottom-65">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="contact-page-map">
								<iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d60021.82409444856!2d-122.40118071595978!3d37.7546723469594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1577786376747!5m2!1sen!2sbd" style={{ border: 0 }} allowFullScreen />
							</div>
						</div>
						<div className="col-lg-4">
							<form className="contact-form-wrap contact-form-bg">
								<h4>Contact Now</h4>
								<div className="rld-single-input">
									<input type="text" placeholder="Name"
										onChange={(e) => onFormChange_handler("contactName", e)}
									/>
								</div>
								<div className="rld-single-input">
									<input type="text" placeholder="Email"
										onChange={(e) => onFormChange_handler("contactMail", e)} />
								</div>
								<div className="rld-single-input">
									<input type="text" placeholder="Phone"
										onChange={(e) => onFormChange_handler("contactPhone", e)} />
								</div>
								<div className="rld-single-select">
									<select className="single-select"
										onChange={(e) => onFormChange_handler("contactSubject", e)}>
										<option disabled selected hidden>Select your option</option>
										<option value="Je souhaite acheter">Je souhaite acheter</option>
										<option value="Je souhaite louer">Je souhaite louer</option>
										<option value="Autre">Autre</option>
									</select>
								</div>
								<div className="rld-single-input">
									<textarea rows={10} placeholder="Message" defaultValue={""}
										onChange={(e) => onFormChange_handler("contactMessage", e)} />
								</div>
								<ReCaptchaV2
									ref={recaptchaRef}
									sitekey={env.RECAPTCHA_SITE_KEY}
									onChange={(e) => onFormChange_handler("token", e)}
								/>
								<div className="btn-wrap text-center">
									<button className="btn btn-yellow"
										onClick={handle_submit}>
										Submit</button>
								</div>
							</form>
						</div>
					</div>
					<div className="row pd-top-92">
						<div className="col-xl-3 col-sm-6">
							<div className="single-contact-info">
								<p><i className="fa fa-phone" />Call Us:</p>
								<h5>(000) 111 222 333</h5>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="single-contact-info">
								<p><i className="fa fa-fax" />Fax:</p>
								<h5>(000) 111 222 333</h5>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="single-contact-info">
								<p><i className="fa fa-envelope" />Have any Question?</p>
								<h5>example@codingeek.net</h5>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="single-contact-info">
								<p><i className="fa fa-phone" />Address</p>
								<h5>216 Trinity Ave, Pasadena,</h5>
								<h5>CA 95046, United States</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Contact

