import API_ENDPOINT from "../../api/api_endpoint";
import { getApi } from "../../api/api_methods";
import NotificationComponent from "../../components/alert-component/component";
import { loadVersion } from "./index.slice";


export function loadVersionAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_VERSION, payload)
            .then((res) => {
                dispatch(loadVersion(res.data))
            })
            .catch((err) => {
                NotificationComponent(false,
                    "error",
                    "Erreur de chargement des données",
                )
            });
    };
}
