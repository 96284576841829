import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    version: {},
};
const referentiel = createSlice({
    name: "referentiel",
    initialState,
    reducers: {
        loadVersion: (state, { payload }) => {
            state.version = payload;
        },
    },
});

export const { loadVersion } = referentiel.actions;

export default referentiel.reducer;
